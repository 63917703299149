/* BlogDetailPage.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer {

}

.article-container {
  @apply lg:mx-auto max-w-5xl sm:mx-4 text-white;
}

.article-content {
  @apply px-8 py-6;
}

.article-title {
  @apply text-3xl font-bold mb-4;
}

.article-content p {
  @apply text-lg leading-relaxed;
}
