@tailwind base;
@tailwind components;
@tailwind utilities;

@layer {
   p {
      color: rgb(105 111 139);
   }
   h3 {
      color: rgb(1 136 159);
   }
   h2 {
      color: rgb(105 111 139);
   }
   h1 {
      font-weight: 800 !important;
      font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
      line-height: 1;
      color: rgb(1 136 159);
   }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: white; /* 設置整個頁面的背景色 */
  overflow-x: hidden; /* 禁用水平滾動條 */
}

html, body, #root {
  height: 100%;
}
