/* PrivacyPolicy.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer {

}
/* Add padding to the container */
/* Apply Tailwind CSS classes */
.terms-container {
  @apply p-14; /* Apply padding using Tailwind CSS */
}

/* Style the heading using Tailwind classes */
.terms-container h1 {
  @apply text-3xl font-bold text-center font-sans;
}

.terms-container h2 {
  @apply text-2xl font-semibold mb-4;
}

/* Style the paragraph using Tailwind classes */
.terms-container p {
  @apply text-base mb-4;
}

.terms-container ul {
  @apply list-disc pl-4 text-gray-400 mb-4;
}

/* Style links within the Privacy Policy using Tailwind classes */
.terms-container a {
  @apply text-blue-500 no-underline hover:underline;
}
